<template>
  <transition name="back-to-top-fade">
    <span class="back-to-top pointer position-fixed d-flex align-items-center justify-content-center rounded-circle" v-if="visible" @click="backToTop">
      <img src="~/mlearn/icons/back-to-top.svg"/>
    </span>
  </transition>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Voltar ao topo'
    },
    visibleoffset: {
      type: [String, Number],
      default: 800
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0
    },
    right: {
      type: String,
      default: '106px'
    },
    bottom: {
      type: String,
      default: '40px'
    },
    scrollFn: {
      type: Function,
      default (eventObject) {
      }
    }
  },
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    window.smoothscroll = () => {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll)
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
      }
    }
    window.addEventListener('scroll', this.catchScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.catchScroll)
  },
  methods: {
    /**
     * Catch window scroll event
     * @return {void}
     */
    catchScroll () {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset)
      const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom)
      this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset
      this.scrollFn(this)
    },
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop () {
      window.smoothscroll()
      this.$emit('scrolled')
    }
  }
}
</script>
<style lang="scss" scoped>
.back-to-top {
  background: var(--white);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.07);
  padding: 10px;
  bottom: 55px;
  right: 40px;
  width: 40px;
  height: 40px;
  @media (max-width: 992px) {
    bottom: 80px;
    right: 20px;
  }
}
</style>
<style>
.back-to-top-fade-enter-active,
.back-to-top-fade-leave-active {
  transition: opacity .7s;
}

.back-to-top-fade-enter,
.back-to-top-fade-leave-to {
  opacity: 0;
}
</style>
