<template>
  <div class="row blog-item" :class="{'topic': topic}">
    <div :class="topic ? 'col-sm-5 col-lg-6' : 'col-sm-5 col-lg-4'">
      <img :src="item.thumbnail" alt="image" class="thumnail pointer w-100 h-auto" @click="redirectDetail"/>
    </div>
    <div :class="topic ? 'col-sm-7 col-lg-6' : 'col-sm-7 col-lg-8'">
      <div class="blog-title pointer display-html" :class="{'relation': relation}" @click="redirectDetail" v-html="$helper.hightLight(item.title, keyword)"></div>
      <div class="blog-time" :class="{'relation': relation}">{{ item.created_at | parseTime('{h}:{i}  {d}/{m}/{y}') }}</div>
      <div class="blog-description pointer display-html" :class="{'relation':relation}" v-html="item.description" @click="redirectDetail"></div>
      <div class="blog-category pt-3" v-if="!relation">
        <nuxt-link v-for="cate in item.category" :key="cate.id" :to="(isBlogApp ? '/blog-app' : '/blog') + '/danh-muc/'+cate.slug">{{ cate.name }}</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    topic: {
      type: Boolean,
      default () {
        return false
      }
    },
    relation: {
      type: Boolean,
      default () {
        return false
      }
    },
    keyword: {
      type: String,
      default () {
        return ''
      }
    }
  },
  computed: {
    isBlogApp () {
      return /blog-app/.test(this.$route.name)
    }
  },
  methods: {
    redirectDetail () {
      this.$router.push((this.isBlogApp ? '/blog-app/' : '/blog/') + this.item.slug)
    }
  }
}
</script>

<style lang="scss" scoped>
  .blog-item{
    .thumnail{
      border-radius: 4px;
      max-height: 300px;
      padding-right: 0;
      @media (min-width: 576px) {
        height: 100%;
        padding-right: 15px;
      }
      @media (min-width: 992px) {
        height: auto;
        padding-right: 20px;
      }
      @media (min-width: 1366px) {
        border-radius: 8px;
      }
    }
    .blog-title{
      font-weight: 500;
      font-size: 18px;
      -webkit-line-clamp: 2;
      padding-top: 10px;
      @media (min-width: 576px) {}
      @media (min-width: 992px) {}
      @media (min-width: 1366px) {}
      &.relation{
        -webkit-line-clamp: 2;
      }
      @media (min-width: 576px) {
        font-size: 18px;
        -webkit-line-clamp: 1;
        padding-top: 0;
        @media (min-width: 576px) {}
        @media (min-width: 992px) {}
        @media (min-width: 1366px) {}
        &.relation{
          -webkit-line-clamp: 1;
        }
      }
      @media (min-width: 992px) {
        font-size: 18px;
        -webkit-line-clamp: 2;
        @media (min-width: 576px) {}
        @media (min-width: 992px) {}
        @media (min-width: 1366px) {}
        &.relation{
          -webkit-line-clamp: 2;
        }
      }
      @media (min-width: 1200px) {
        -webkit-line-clamp: 2;
        &.relation{
          -webkit-line-clamp: 1;
        }
      }
      @media (min-width: 1366px) {
        font-size: 22px;
        -webkit-line-clamp: 2;
        @media (min-width: 576px) {}
        @media (min-width: 992px) {}
        @media (min-width: 1366px) {}
        &.relation{
          -webkit-line-clamp: 1;
        }
      }
    }
    .blog-time{
      padding-top: 0;
      font-size: 14px;
      color: #69696A;
      &.relation{
        padding-top: 0;
      }
      @media (min-width: 1200px) {
        padding-top: 0.25rem;
        &.relation{
          padding-top: 0;
        }
      }
      @media (min-width: 1366px) {
        padding-top: 0.5rem;
        &.relation{
          padding-top: 0.25rem;
        }
      }
    }
    .blog-description{
      font-size: 15px;
      -webkit-line-clamp: 3;
      padding-top: 0.5rem;
      &.relation{
        -webkit-line-clamp: 3;
        padding-top: 0.5rem;
      }
      @media (min-width: 576px) {
        -webkit-line-clamp: 2;
        &.relation{
          -webkit-line-clamp: 2;
        }
      }
      @media (min-width: 992px) {}
      @media (min-width: 1200px) {
        font-size: 16px;
        -webkit-line-clamp: 3;
        padding-top: 0.75rem;
        &.relation{
          -webkit-line-clamp: 2;
          padding-top: 0.5rem;
        }
      }
      @media (min-width: 1366px) {
        -webkit-line-clamp: 4;
        padding-top: 1rem;
        &.relation{
          -webkit-line-clamp: 3;
          padding-top: 0.5rem;
        }
      }
    }
    .blog-category{
      a{
        background: var(--white);
        border: 1px solid #C4C4C4;
        border-radius: 16px;
        padding:5px 12px;
        font-size: 14px;
        line-height: 151.7%;
        color: #75777B;
        margin-right: 15px;
        @media (min-width: 576px) {}
        @media (min-width: 992px) {}
        @media (min-width: 1366px) {}
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .highlight{
      background: yellow;
      padding:0 5px;
    }
    &.topic{
      .thumnail{
        @media (min-width: 576px) {
          padding-right: 15px;
        }
        @media (min-width: 992px) {
          padding-right: 20px;
        }
        @media (min-width: 1920px) {
          padding-right: 30px;
        }
      }
      .blog-title{
        font-size: 20px;
        @media (min-width: 1366px) {
          font-size: 28px;
        }
      }
      .blog-description{
        font-size: 15px;
        @media (min-width: 1366px) {
          font-size: 18px;
        }
      }
      .blog-category{
        a{
          font-size: 14px;
          @media (min-width: 1366px) {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
