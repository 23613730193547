<template>
  <div class="answer-item-me">
    <div class="block-show">
      <div class="item-title text-primary" @click.prevent="redirectDetail">
        {{ item.class.name }} &#8226; {{ item.category.name }} <span class="ml-3 time-ago">{{ item.created_at|ralativeTime }}</span>
      </div>
      <div class="item-content">
        <div class="pointer content-question" @click.prevent="redirectDetail">Xem nội dung câu hỏi</div>
        <div class="item-line"></div>
        <div class="answer-title mb-2">Trả lời</div>
        <span class="pointer" v-if="showMore" @click.prevent="redirectDetail">
          <vue-mathjax :formula="item.content" :safe="false"></vue-mathjax>
        </span>
        <span class="pointer" v-else @click.prevent="redirectDetail">
          <vue-mathjax :formula="item.is_formula ? item.content : $helper.getSubString(item.content, limit)" :safe="false"></vue-mathjax>
        </span>
        <span class="content-more pointer" v-if="!showMore && !item.is_formula" @click.prevent="showMore = true">Xem thêm</span>
      </div>
      <div v-if="item.image && item.image.length" class="item-image" @click.prevent="redirectDetail">
        <div class="image-container">
          <img v-for="img in item.image" :src="img" :key="img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      showMore: true,
      limit: 500,
      NoAvatar
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'user'
    ])
  },
  created () {
    if (this.item.content.length > this.limit) {
      this.showMore = false
    }
  },
  methods: {
    redirectDetail () {
      this.$router.push('/cau-hoi-cong-dong/' + this.item.question_id)
    }
  }
}
</script>

<style lang="scss" scoped>
.answer-item-me {
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 24px;

  .block-show {
    .item-title {
      font-weight: 500;
      font-size: 14px;

      .time-ago {
        font-size: 12px;
        color: #65676B;
      }
    }

    .item-content {
      padding: 15px 0;
      font-size: 16px;

      .content-more {
        color: var(--primary);
        z-index: 99;
      }

      .content-question {
        font-weight: 500;
        font-size: 16px;
        color: var(--primary);
      }

      .item-line {
        border-bottom: 1px solid #E2E2E2;
        margin: 10px 0;
      }

      .answer-title {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .item-image {
      margin-bottom: 15px;

      .image-container {
        border: 1px solid #E2E2E2;
        border-radius: 8px;
        margin-bottom: 16px;
        text-align: center;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          max-width: 100%;
          max-height: 300px;
        }
      }
    }
  }
}
</style>
