<template>
  <div class="rating-stars">
    <div class="rating-stars__stars align-items-center" v-if="isFloat">
      <div class="rating-stars__star-wrapper" v-for="(star,ind) in 5" :key="ind">
        <template v-if="star <= Math.trunc(active)">
          <div class="rating-stars__lit w-100">
            <StarActive/>
          </div>
        </template>
        <template v-else>
          <template v-if="(star - 1) == Math.trunc(active)">
            <div class="rating-stars__lit" :style="{width:(isFloat * 100)+'%'}">
              <StarActive/>
            </div>
          </template>
          <template v-else>
            <div class="rating-stars__lit" style="width:0px;">
              <StarActive/>
            </div>
          </template>
        </template>
        <StarNone class="inactive" @click="ratingValue(star)"/>
      </div>
      <span class="number-rate" v-if="active">{{ avgStar }} ({{ numberRate }} lượt đánh giá)</span>
      <span class="number-rate" v-else-if="!active && user && user.uuid !== userId">Câu trả lời này có hữu ích với bạn?</span>
    </div>
    <div class="rating-stars__stars align-items-center" v-else>
      <div class="rating-stars__star-wrapper" v-for="(star,ind) in 5" :key="ind">
        <template v-if="ind < active">
          <div class="rating-stars__lit w-100">
            <StarActive/>
          </div>
        </template>
        <StarNone class="inactive" @click="ratingValue(star)"/>
      </div>
      <span class="number-rate align-self-end" v-if="active || (user && user.uuid === userId)">{{ avgStar }} ({{ numberRate }} lượt đánh giá)</span>
      <span class="number-rate align-self-end" v-else-if="!active && user && user.uuid !== userId">Câu trả lời này có hữu ích với bạn?</span>
    </div>
  </div>
</template>
<script>
import StarNone from '~/assets/mlearn/icons/rating/none.svg?inline'
import StarActive from '~/assets/mlearn/icons/rating/active.svg?inline'
export default {
  components: {
    StarNone,
    StarActive
  },
  props: {
    active: {
      type: Number,
      default () {
        return 0
      }
    },
    numberRate: {
      type: Number,
      default () {
        return 0
      }
    },
    avgStar: {
      type: Number,
      default () {
        return 0
      }
    },
    objectId: {
      type: String,
      default () {
        return ''
      }
    },
    userId: {
      type: String,
      default () {
        return ''
      }
    }
  },
  computed: {
    isFloat () {
      return this.active - Math.trunc(this.active)
    },
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    ratingValue (value) {
      if (this.active) { return }
      if (this.user && this.user.uuid === this.userId) { return }
      this.$emit('rating', value)
    }
  }
}
</script>
<style lang="scss" scoped>
  svg:not(:root) {
    overflow: hidden;
  }
  .rating-stars{
    position: relative;
    display: inline-block;
    .rating-stars__stars{
      display: flex;
      flex-wrap: wrap;
      .rating-stars__star-wrapper{
        position: relative;
        margin-right: 4px;
        .rating-stars__lit{
          overflow: hidden;
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 1;
        }
        svg{
          &.inactive{
            cursor: pointer;
          }
        }
      }
      .number-rate{
        color: #6B6D70;
        padding-top: 5px;
        font-size: 14px;
        padding-left: 10px;
        @media (max-width:576px) {
          font-size: 12px;
          padding-left: 0;
        }
      }
    }
  }
</style>
