<template>
  <section v-if="bannerActive" :style="{'--mobile': 'url('+(bannerMobile ? bannerMobile.link : bannerActive.link)+')'}">
    <img class="banner-item-web-mobile w-100 pointer" v-if="inSystem() || !bannerActive.url" :src="bannerActive.link" :alt="bannerActive.name" @click="handerClick()" >
    <a v-else :href="bannerActive.url" target="_blank">
      <img class="banner-item-web-mobile w-100" :src="bannerActive.link" :alt="bannerActive.name">
    </a>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    type: {
      type: String,
      default () {
        return 'BANNER_HOME'
      }
    }
  },
  data () {
    return {
      bannerActive: null,
      bannerMobile: null
    }
  },
  computed: {
    ...mapState([
      'banner',
      'authenticated'
    ])
  },
  created () {
    this.bannerMobile = this.banner.find(item => item.code === (this.type + '_MOBILE'))
    this.bannerActive = this.banner.find(item => item.code === this.type)
  },
  methods: {
    inSystem () {
      if (!this.bannerActive) { return false }
      const url = this.bannerActive.url
      switch (url) {
        case '#login':
        case '#register':
        case '#question':
          return true
        default:
          return false
      }
    },
    handerClick () {
      if (!this.bannerActive.url) { return false }
      const url = this.bannerActive.url
      switch (url) {
        case '#login':
          if (!this.authenticated) {
            this.$bvModal.show('modal-login')
          }
          break
        case '#register':
          if (!this.authenticated) {
            this.$bvModal.show('modal-register')
          }
          break
        case '#question':
          if (!this.authenticated) {
            this.$bvModal.show('modal-login')
          } else {
            this.showQuestion()
          }
          break
        default:
          console.log('no event')
          break
      }
    },
    showQuestion () {
      this.$store.dispatch('setShowQuestionDialog', true)
    }
  }
}
</script>
<style lang="scss">
  .banner-item-web-mobile{
    content: var(--mobile);
    @media (min-width:992px) {
      content: none;
    }
    @media (min-width:1366px) {
    }
    @media (min-width:1920px) {
    }
  }
</style>
