<template>
  <VueSlickCarousel v-if="banners.length" v-bind="carouselSettings">
    <!-- <img v-for="banner in banners" :key="banner.id" :src="banner.link" alt="banner" class="w-100 banner-item"> -->
    <BannerItem v-for="banner in banners" :key="banner.id" :type="banner.code" class="w-100 banner-item"/>
  </VueSlickCarousel>
</template>

<script>
import { mapState } from 'vuex'
import BannerItem from '~/components/mlearn/banner/item.vue'
export default {
  components: {
    BannerItem
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      banners: [],
      carouselSettings: {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  },
  computed: {
    ...mapState([
      'banner',
      'isMobile'
    ]),
    bannerWeb () {
      return this.banner.filter(banner => banner.code.includes('BANNER_BLOG'))
    },
    bannerMobile () {
      return this.banner.filter(banner => banner.code.includes('BANNER_WAP_BLOG'))
    }
  },
  created () {
    this.banners = this.isMobile ? this.bannerMobile : this.bannerWeb
  }
}
</script>
