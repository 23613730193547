<template>
  <div class="answer-item position-relative">
    <div class="time-line position-absolute h-100"></div>
    <div class="time-line-dot position-absolute rounded-circle d-flex align-items-center justify-content-center"></div>
    <div class="time-line-dot-end position-absolute d-none align-items-center justify-content-center"></div>
    <div class="comment">
      <div class="user-info form-data">
        <img :src="item.user_avatar || NoAvatar" class="avatar rounded-circle"/>
        <div class="user-info content">
          <div class="user-name text-break">{{ hidePhoneNumber(item.fullname) }}</div>
          <div class="time-ago animation-loading">
            <span>Đ</span>
            <span>a</span>
            <span>n</span>
            <span class="mr-1">g</span>
            <span>t</span>
            <span>r</span>
            <span class="mr-1">ả</span>
            <span>l</span>
            <span>ờ</span>
            <span class="mr-1">i</span>
            <span>c</span>
            <span>â</span>
            <span class="mr-1">u</span>
            <span>h</span>
            <span>ỏ</span>
            <span class="mr-1">i</span>
            <span><img src="~/mlearn/icons/question/detail/typing.gif" style="width:22px;"/></span>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      NoAvatar
    }
  },
  methods: {
    hidePhoneNumber (name) {
      if (/^\d+$/.test(name)) {
        return name.substring(0, 6) + '***'
      } else {
        return name
      }
    }
  }
}
</script>

<style lang="scss">
$number: 17;
@keyframes animation-loading {
  0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-10px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}

.animation-loading {
  display: flex;
  flex-direction: row;

  span_ {
    animation-name: animation-loading;
    animation-duration: 2.5s;
    animation-iteration-count: 1;
    @for $i from 1 through $number {
      &:nth-child(#{$i}) {
        animation-delay: ($i * 0.15s);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.answer-item {
  .time-line {
    left: 4px;
    border-left: 1px solid #E2E2E2;
  }

  .time-line-dot {
    background-color: #e4e7ed;
    left: -1px;
    width: 12px;
    height: 12px;
    top: -5px;
  }

  .time-line-dot-end {
    background-color: #e4e7ed;
    left: -1px;
    width: 12px;
    height: 12px;
    bottom: 0px;
  }

  .comment {
    top: -3px;
    padding-top: 26px;
    padding-bottom: 30px;
    padding-left: 35px;
    margin-top: 16px;
    @media (max-width: 1366px) {
      padding-top: 10px;
      padding-bottom: 0;
      padding-left: 20px;
      margin-top: 0;
    }
    @media (max-width: 576px) {
      padding-top: 16px;
      padding-bottom: 0;
      padding-left: 0;
      margin-top: 0;
    }

    .user-info {
      .avatar {
        width: 46px;
        height: 46px;
        margin-right: 15px;
        @media (max-width: 576px) {
          margin-right: 10px;
        }
      }

      .user-name {
        font-weight: bold;
        font-size: 16px;
        @media (max-width: 1366px) {
          font-size: 15px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
        }

        .is-guru {
          margin-left: 10px;
          font-weight: 500;
          color: #868789;
          font-size: 14px;
          @media (max-width: 576px) {
            font-size: 13px;
          }
        }
      }

      .time-ago {
        font-size: 12px;
        color: #65676B;
      }

      .guru-verify {
        color: #27AE60;
        font-size: 14px;
        @media (max-width: 576px) {
          font-size: 13px;
        }
      }
    }

    .content-answer {
      padding-top: 10px;
      font-size: 16px;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    .image-answer {
      padding-top: 10px;

      img {
        border-radius: 8px;
        max-height: 300px;
        @media (max-width: 1366px) {
        }
        @media (max-width: 576px) {
        }
      }
    }

    .answer-action {
      padding-top: 15px;

      .action-rating {
        img {
          margin-right: 4px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .action-more {
        @media (max-width: 576px) {
          align-self: flex-start;
        }

        .action {
          cursor: pointer;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }

          .action-number {
            font-size: 14px;
            vertical-align: text-top;
          }
        }

        .edit-answer {
          color: var(--primary);
        }
      }
    }

    .form-comment {
      margin-top: 20px;
      margin-bottom: 15px;
      @media (max-width: 1366px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      @media (max-width: 576px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .avatar {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }

      .content {
        input {
          background: #EBEBEB;
          border-radius: 22px;
          padding: 14px 16px 13px;
          font-size: 14px;

          &::placeholder {
            color: #6B6D70;
          }

          @media (max-width: 1366px) {
            padding: 10px 12px;
          }
          @media (max-width: 576px) {
            padding: 10px 12px;
          }
        }
      }
    }

    .list-comment {
      max-height: 300px;
      @media (max-width: 1366px) {
        max-height: 200px;
      }
      @media (max-width: 576px) {
        max-height: 200px;
      }
    }
  }

  .line {
    border-bottom: 1px solid #E2E2E2;
    margin-left: 28px;
    @media (max-width: 576px) {
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss">
.answer-item {
  .action-more {
    .action {
      &.active {
        color: var(--primary);
      }
    }
  }
}
</style>
